import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';

import EmojiObjectsTwoToneIcon from '@mui/icons-material/EmojiObjectsTwoTone';
import InstallMobileTwoToneIcon from '@mui/icons-material/InstallMobileTwoTone';
import CurrencyYenTwoToneIcon from '@mui/icons-material/CurrencyYenTwoTone';
import MailOutlineTwoToneIcon from '@mui/icons-material/MailOutlineTwoTone';
import HelpOutlineTwoToneIcon from '@mui/icons-material/HelpOutlineTwoTone';
import AppleIcon from '@mui/icons-material/Apple';


export function ButtonAppBar() {
    
    const [drawerOpened, setDrawerOpened] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ background: "#f9db00", boxShadow: "none", border: 0 }}>
            <Toolbar>
                
                <Box component="div" sx={{ flexGrow: 1}}>
                    <img
                        src={"img/logo.svg"}
                        alt=""
                        style={{ height: "1.25rem"}}
                    />
                </Box>

                <IconButton
                    size="large"
                    edge="start"
                    color="#000"
                    aria-label="menu"
                    onClick={() => setDrawerOpened(true)}
                    sx={{
                        mr: 0,
                    }}
                >
                    <MenuIcon />
                </IconButton>
            </Toolbar>
        </AppBar>

        <Drawer
            anchor={'right'}
            open={drawerOpened}
            onClose={() => setDrawerOpened(false)}
        >
            <Box
                sx={{
                    width: "60vw",
                }}
            >
                
                <nav>
                    <List>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#co" onClick={() => setDrawerOpened(false)}>
                                <ListItemIcon>
                                    <EmojiObjectsTwoToneIcon />
                                </ListItemIcon>
                                <ListItemText primary="conekutaで実現する４つのこと" />
                            </ListItemButton>
                        </ListItem>
                        
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#fl"　onClick={() => setDrawerOpened(false)}>
                                <ListItemIcon>
                                    <InstallMobileTwoToneIcon />
                                </ListItemIcon>
                                <ListItemText primary="導入の流れ" />
                            </ListItemButton>
                        </ListItem>
                        
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#prc" onClick={() => setDrawerOpened(false)}>
                                <ListItemIcon>
                                    <CurrencyYenTwoToneIcon />
                                </ListItemIcon>
                                <ListItemText primary="料金" />
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <Divider />

                    <List>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#faq" onClick={() => setDrawerOpened(false)}>
                                <ListItemIcon>
                                    <HelpOutlineTwoToneIcon />
                                </ListItemIcon>
                                <ListItemText primary="よくある質問" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#ct" onClick={() => setDrawerOpened(false)}>
                                <ListItemIcon>
                                    <MailOutlineTwoToneIcon />
                                </ListItemIcon>
                                <ListItemText primary="お問い合わせ" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton
                                component="a"
                                href="https://apps.apple.com/jp/app/conekuta/id6483865072"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => setDrawerOpened(false)}
                            >
                                <ListItemIcon>
                                    <AppleIcon /> {/* Apple関連アイコン（必要に応じて変更） */}
                                </ListItemIcon>
                                <ListItemText primary="お客様専用アプリ" />
                            </ListItemButton>
                        </ListItem>
                    </List>

                </nav>

            </Box>
        </Drawer>

    </Box>
  );
}
