import * as React from "react";
import {ButtonAppBar} from "./ButtonAppBar";
import Feedback from "./Feedback";
import Faq from "./Faq";


const Home = () => {

  const handleButtonClick = () => {
    window.open("https://shop.conekuta.com", "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <ButtonAppBar/>

      <main className="top">
        <section id="fv" className="fv link_area">
          <div className="fv_inner">
            <div className="fv_flex_item">
              <div className="fv_left">
                <div className="fv_catch_copy">
                  <h1>すぐに、<br />つながる。</h1>
                  <p>
                    conekutaはポイントカードアプリを<br />
                    簡単に作成できるサービスです。
                  </p>
                  <div className="fv_features">
                    <p className="fv_feature">
                      <span className="fv_txt_top">初期費用</span>
                      <span className="fv_txt_btm fv_txt_lg_num">0<span className="fv_txt_sm">円</span></span>
                    </p>
                    <p className="fv_feature">
                      <span className="fv_txt_top">最低契約期間</span>
                      <span className="fv_txt_btm">なし</span>
                    </p>
                    <p className="fv_feature">
                      <span className="fv_txt_top">導入スピード</span>
                      <span className="fv_txt_btm fv_speed">
                        <span className="fv_txt_vertical">最短</span>
                        <span className="fv_txt_five fv_txt_lg_num">5</span>
                        <span className="fv_txt_sm fv_txt_minute">分</span>
                      </span>
                    </p>
                    <p className="fv_feature">
                      <span className="fv_txt_top">クーポンで</span>
                      <span className="fv_txt_btm fv_speed">
                        <span className="fv_txt_vertical">初月</span>
                        <span className="fv_txt_five fv_txt_free">無</span>
                        <span className="fv_txt_five fv_txt_free">料</span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="fv_app_area">
                <img className="fv_app" src="img/fv_app.webp" alt="アプリイメージ" />
              </div>
            </div>
            <div className="fv_trial">
              <img className="fv_trial_img" src="img/fv_trial.svg" alt="クーポンコードを入力で、30日間無料トライアル" />
              <div>
                {/* <button>準備中...</button> */}
                <button className="cta-button" onClick={handleButtonClick}>試してみる</button>
              </div>
              <p className="note">※ご利用いただくには、Googleアカウントが必要です。</p>
            </div>
          </div>
        </section>

        {/* conekutaで実現する4つのこと */}
        <section id="co" className="co link_area">
          <div className="co_inner">
            <h2>conekutaで実現する4つのこと</h2>
            <ul className="co_flex_item">
              <li>
                <img className="co_img" src="img/co_img01.svg" alt="アプリ作成画像" />
                <p className="co_txt_title">スマホ1台で今すぐアプリを作れる</p>
                <p className="co_txt_description">
                  面倒な手続きなどは不要で、いつでもどこでもスマホ１台でポイントカードアプリが作れます。
                </p>
              </li>
              <li>
                <img className="co_img" src="img/co_img02.svg" alt="スモールスタート" />
                <p className="co_txt_title">スモールスタートで今すぐ始められる</p>
                <p className="co_txt_description">初期費用０円で月額制なので、気軽に試せます。</p>
              </li>
              <li>
                <img className="co_img" src="img/co_img03.svg" alt="シンプル" />
                <p className="co_txt_title">画面がシンプルで使いやすい</p>
                <p className="co_txt_description">アプリのデザインがシンプルなので店舗スタッフもご来店者様もすぐに使えます。</p>
              </li>
              <li>
                <img className="co_img" src="img/co_img04.svg" alt="クーポン" />
                <p className="co_txt_title">クーポン機能でリピーター獲得</p>
                <p className="co_txt_description">作成したクーポンがリアルタイムで顧客に届くため、リピーターの獲得に効果的です。</p>
              </li>
            </ul>
          </div>
        </section>

        {/* 導入の流れ */}
        <section id="fl" className="fl link_area">
          <div className="fl_inner">
            <h2>導入の流れ</h2>
            <div className="fl_flex">
              <img className="fl_img" src="img/flow.svg" alt="導入の流れ" />
              <ul>
                <li>
                  <p className="fl_txt_title"><span className="fl_num">01.</span>ポイントカード作成</p>
                  <p className="fl_txt_description">
                    「試してみる」ボタンから、簡単な店舗情報登録に進みます。登録が成功すると、ポイントカードアプリが自動作成されます。
                  </p>
                </li>
                <li>
                  <p className="fl_txt_title"><span className="fl_num">02.</span>ポイントカード配布</p>
                  <p className="fl_txt_description">
                    お客様に専用のスマホアプリをインストールしていただき、ポイントカードを配布します。※現在はiphoneのみ対応
                  </p>
                  <a
                    href="https://apps.apple.com/jp/app/conekuta/id6483865072"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fl_app_link"
                  >
                    お客様専用アプリはこちら！
                  </a>
                </li>
                <li>
                  <p className="fl_txt_title"><span className="fl_num">03.</span>運用開始</p>
                  <p className="fl_txt_description">
                    店舗用のWebアプリで、「ポイント付与・クーポン作成・カードのデザイン編集」などの機能をお使いいただけます。
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>

        {/* 料金 */}
        <section id="prc" className="prc link_area">
          <div className="prc_inner">
            <h2>料金</h2>
            <b>
              <p className="prc_description">
                基本料金のみで、シンプルな料金体系。<br />
                追加料金はかからないので安心！
              </p>
            </b>
            <img className="prc_img" src="img/price.svg" alt="料金表" />
          </div>

          {/* CTA */}
          <div id="cta" className="cta">
            <div className="cta_area">
              <img className="fv_trial_img" src="img/fv_trial.svg" alt="クーポンコードを入力で、30日間無料トライアル" />
              <div>
                {/* <button>準備中...</button> */}
                <button className="cta-button" onClick={handleButtonClick}>試してみる</button>
              </div>
              <p className="note">※ご利用いただくには、Googleアカウントが必要です。</p>
            </div>
          </div>
        </section>

        {/* よくある質問 */}
        <section id="faq" className="faq link_area">
          <h2>よくある質問</h2>
            <Faq/>
        </section>

        {/* お問い合わせ */}
        <section id="ct" className="ct link_area">
          <div className="ct_inner">
            <h2>お問い合わせ</h2>
            <Feedback/>
          </div>
        </section>

      </main>
      
      <footer>
        <div className="f_nav_area">
          <img className="f_logo" src="img/logo.svg" alt="ロゴ" />
          <nav>
            <ul>
              <li><a href="#co">conekutaで実現する4つのこと</a></li>
              <li><a href="#fl">導入の流れ</a></li>
              <li><a href="#prc">料金</a></li>
              <li><a href="#faq">よくある質問</a></li>
              <li><a href="#cct">お問い合わせ</a></li>
              <li>
                <a
                  href="https://apps.apple.com/jp/app/conekuta/id6483865072"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  お客様専用アプリ
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <ul className="f_agreements">
          <li><a href="./terms_of_service.html" target="_blank" rel="noopemer noreferrer">利用規約</a></li>
          <span className="f_separator"></span>
          <li><a href="./privacy_policy.html" target="_blank" rel="noopemer noreferrer">プライバシーポリシー</a></li>
          <span className="f_separator"></span>
          <li><a href="./trade_info.html" target="_blank" rel="noopemer noreferrer">特定商取引法に基づく表記</a></li>
        </ul>
        <p className="f_copyright">© 2024 conekuta</p>
      </footer>

    </>
  );
};

export default Home;
